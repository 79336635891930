@import './components/global';
.i-tit {
    font-size: 0.3rem;
    font-weight: normal;
}

.i-indent {
    padding-left: 0.9rem;
    box-sizing: border-box;
}

// 关于我们
.i-about-box {
    padding: 1.4rem 0;
    box-sizing: border-box;
    .des {
        font-size: 0.16rem;
        line-height: 1.875;
    }
    .fl {
        width: 51%;
        .des {
            padding: 0.6rem 0 0.5rem;
        }
    }
    .fr {
        position: static;
        width: 32%;
        box-sizing: border-box;
        .tit {
            font-size: 0.22rem;
            padding: 0.35rem 0 0.4rem;
        }
    }
}

.ib-page {
    position: absolute;
    top: 1.4rem;
    right: 0;
    z-index: 1;
    .line {
        display: inline-block;
        width: 0.6rem;
        height: 1px;
        background-color: #08101f;
        position: relative;
        transition: all 0.1s;
        vertical-align: middle;
        transition: all 0.3s;
        @media(max-width: 1200px) {
            width: 0.4rem;
        }
        &:after {
            content: "";
            display: block;
            width: 66.666666%;
            height: 100%;
            background-color: #fff;
            z-index: 1;
            transition: all 0.3s;
            opacity: 1;
        }
    }
    .text {
        position: absolute;
        top: 0;
        left: -8px;
        transform: translate(-150%, 0);
        color: #666;
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
    }
    li {
        position: relative;
        cursor: pointer;
        &~li {
            margin-top: 0.45rem;
        }
        &.on {
            .line {
                &:after {
                    width: 0rem;
                }
            }
            .text {
                opacity: 1;
                transform: translate(-100%, 0);
            }
        }
    }
}

//科研实力
.i-strength {
    width: 100%;
    padding-bottom: 0.9rem;
    background: url('/assets/image/i-strength.jpg') no-repeat center;
    background-size: cover;
    color: #fff;
    .tit {
        padding-top: 0.9rem;
    }
    .a-img {
        text-align: center;
        margin-top: 3.5%;
    }
    .info {
        width: 33%;
        font-size: 0.16rem;
        line-height: 1.5;
        margin: 5% auto 0;
    }
    .box {
        width: 37%;
        position: relative;
        display: inline-block;
        .ring {
            position: absolute;
            top: -4%;
            left: 50%;
            height: auto;
            width: 52.8%;
            transform: translateX(-50%);
            z-index: 1;
            transform-origin: center;
            animation: myRotate 50s linear infinite;
        }
    }
    .list {
        li {
            position: absolute;
            z-index: 1;
            width: 40%;
            p {
                position: absolute;
                top: 44%;
                left: 50%;
                font-size: 0.16rem;
                transform: translate(-50%, -50%);
                z-index: 1;
            }
        }
        .c-box {
            width: 33%;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-48%, -15%);
            z-index: 1;
            h3 {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-35%, -60%);
                z-index: 1;
                font-size: 0.3rem;
            }
            .ring2 {
                width: 26%;
                position: absolute;
                top: 46%;
                left: 57%;
                transform-origin: top left;
                // transform: translate(-16%, -34%);
                animation: myRotate2 10s linear infinite;
            }
        }
        .lt {
            top: 0;
            left: 0;
            transform: translate(-40%, -0%);
        }
        .lb {
            bottom: 0;
            left: 0;
            transform: translate(-40%, 0%);
        }
        .rt {
            top: 0;
            right: 0;
            transform: translate(40%, -0%);
        }
        .rb {
            bottom: 0;
            right: 0;
            transform: translate(40%, 0%);
        }
        .rt,
        .rb {
            .c-box {
                left: unset;
                right: 0;
                transform: translate(48%, -15%);
                h3 {
                    transform: translate(-88%, -60%);
                }
                .ring2 {
                    left: 46%;
                }
            }
        }
    }
}

@keyframes myRotate {
    from {
        transform: translateX(-50%) rotate(0);
    }
    to {
        transform: translateX(-50%) rotate(360deg);
    }
}

@keyframes myRotate2 {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

// 产品中心
.i-product {
    padding-top: 1.2rem;
    overflow: hidden;
    .info {
        padding-bottom: 0.9rem;
        @include clear();
        p {
            width: 50%;
            line-height: 1.6;
            font-size: 0.16rem;
        }
    }
    .p-list {
        li {
            width: 61.25%;
            // transform: translateX(-50%);
        }
    }
}

#p-swiper {
    position: relative;
    padding-bottom: 1rem;
    .page {
        padding-top: 1rem;
        font-size: 0;
        text-align: center;
        .item {
            display: inline-block;
            width: 0.48rem;
            height: 3px;
            background-color: #ebebeb;
            transition: all 0.3s;
            &.on {
                background-color: $color;
            }
        }
    }
}

// 新闻中心
.i-news {
    padding-top: 2.2rem;
    overflow: hidden;
    .tit-box {
        display: inline-block;
    }
    .text-box {
        padding-bottom: 1.2rem;
    }
    .more {
        display: block;
        font-size: 0.14rem;
        line-height: 1.8;
        padding-top: 0.15rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #929292;
        img {
            vertical-align: middle;
        }
    }
    @media (max-width:1440px) {
        padding-top: 1rem;
        .text-box {
            padding-bottom: 0.7rem;
        }
    }
}

.new-black {
    background: linear-gradient(to right, #f7f7f7 75.7%, #fff 24.3%);
    padding: 2rem 0;
    .tit {
        font-size: 0.24rem;
        line-height: 1.4;
        padding-bottom: 0.4rem;
    }
    .des {
        font-size: 0.16rem;
        line-height: 1.8;
        @include duo(9);
        max-height: 2.5rem;
    }
    .info {
        width: 47%;
    }
    .info-box {
        height: 4.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // background-color: #f7f7f7;
    }
    .img-box {
        position: absolute;
        bottom: 0;
        right: -1.6rem;
        width: 43%;
        z-index: 1;
        img {
            width: 100%;
        }
    }
    .view {
        a {
            display: block;
            text-align: center;
            font-size: 0.16rem;
            color: #fff;
            height: 0.44rem;
            line-height: 0.44rem;
            width: 1.72rem;
            border-radius: 0.22rem;
            background-color: $color;
            position: relative;
            text-indent: 3em;
        }
        .icon {
            position: absolute;
            top: 50%;
            left: 0.22rem;
            z-index: 1;
            width: 0.24rem;
            transform: translateY(-50%);
        }
    }
    .page {
        width: 3px !important;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0 !important;
        z-index: 1;
        span {
            transition: all 0.3s;
            background-color: #ebebeb;
            width: 100%;
            height: 50%;
            display: block;
        }
        .on {
            background-color: $color;
        }
    }
    @media (max-width:1440px) {
        padding: 1rem 0;
    }
}

//介绍服务
.i-service {
    padding-top: 1.2rem;
    padding-bottom: 2.8rem;
    .tit {
        padding-bottom: 3rem;
    }
    .bg-img {
        position: absolute;
        right: 0;
        top: -2rem;
        z-index: -1;
        width: 72%;
    }
    @media(max-width:1440px) {
        padding-bottom: 2rem;
    }
}

.s-box {
    background-color: #f7f7f7;
    padding: 1rem 1.28rem 1.1rem;
    width: 49%;
    box-sizing: border-box;
    margin-left: -10%;
    .tab {
        font-size: 0;
        padding-top: 0.45rem;
        position: relative;
        h3 {
            font-size: 0.3rem;
            display: inline-block;
            width: 50%;
            border-bottom: 3px solid #cecece;
            padding-bottom: 0.15rem;
            cursor: pointer;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                background: url('/assets/image/service_text.png') no-repeat left top;
                background-size: auto 0.56rem;
                transition: all 0.2s;
                z-index: 0;
                pointer-events: none;
            }
            &:nth-child(2):after {
                background-image: url('/assets/image/service_text2.png');
                background-position: right top;
            }
            &.on {
                border-color: #333333;
                &:after {
                    opacity: 1;
                }
            }
            &:nth-child(2) {
                text-align: right;
            }
        }
    }
    .tab-list {
        padding-top: 0.5rem;
        .text {
            color: rgba(0, 0, 0, 0.73);
            line-height: 1.875;
            font-size: 0.16rem;
            height: 1.5rem;
            @include duo(5);
        }
    }
    .tags {
        @include clear();
        margin: 0 -0.38rem;
        li {
            float: left;
            margin: 0.3rem 0.4rem 0;
            text-align: center;
            .img-box {
                width: 0.52rem;
                height: 0.52rem;
                margin: 0 auto;
                margin-bottom: 0.18rem;
                @include obj-img(contain);
            }
            p {
                font-size: 0.13rem;
            }
        }
    }
    @media (max-width:1440px) {
        width: 55%;
        min-width: 6rem;
        margin-left: -10%;
    }
}

// 咨询
.i-consult {
    background: #fcfcfc url('/assets/image/i_consult_bg.png') no-repeat right top;
    background-size: auto 100%;
    padding: 0.6rem;
    .tit {
        font-size: 0.4rem;
        font-weight: normal;
        padding-bottom: 0.5rem;
        color: #3c3c3c;
    }
    .consult-box {
        @include clear();
    }
    .consult-tel {
        float: right;
        width: 30%;
        h5 {
            font-size: 0.18rem;
            text-align: center;
            color: #3c3c3c;
            padding: 0.1rem 0 0.3rem;
        }
        .tel-num {
            font-size: 0.4rem;
            color: $color;
            vertical-align: middle;
            padding-left: 0.2rem;
        }
        .tel-img {
            height: 0.34rem;
            width: auto;
            vertical-align: middle;
        }
        .tel-box {
            white-space: nowrap;
        }
    }
    .consult-form {
        float: left;
        width: 62.5%;
        @include clear();
    }
    .form-item {
        float: left;
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: top;
        height: 1rem;
        label {
            font-size: 0.18rem;
            color: #3c3c3c;
            flex-shrink: 0;
            line-height: 0.66rem;
            width: 1rem;
        }
        .from-control {
            width: 100%;
        }
        input,
        .my-ele {
            width: 100%;
            height: 0.66rem;
            line-height: 0.66rem;
            box-sizing: border-box;
            padding: 0 0.2rem;
            border: 1px solid #e7e7e7;
            border-radius: 1px;
            transition: all 0.3s;
            outline: none;
            color: #666;
        }
        .mini {
            input,
            .my-ele {
                width: 1.6rem;
            }
        }
    }
    .form-explain {
        font-size: 0.14rem;
        padding-top: 0.06rem;
        padding-bottom: 0.1rem;
        color: red;
    }
    .submit-btn {
        background-color: $color;
        color: #fff;
        font-size: 0.16rem;
        line-height: 0.5rem;
        width: 1.7rem;
        border-radius: 0.25rem;
        outline: none;
        border: none;
        cursor: pointer;
    }
    @media(max-width:1200px) {
        .form-item {
            width: 100%;
            input {
                width: 100% !important;
            }
        }
    }
}

@media(max-width:$divide) {
    .i-tit {
        font-size: 0.36rem;
    }
    .i-indent {
        padding-left: 0;
    }
    .i-about-box {
        padding: 0.7rem 0 0.6rem;
        .fl,
        .fr {
            float: none;
            width: 100%;
        }
        .des {
            font-size: 0.26rem;
            color: #555;
        }
        .fl {
            .des {
                padding: 0.3rem 0 0.4rem;
            }
        }
        .fr {
            .tit {
                font-size: 0.36rem;
                padding: 0.6rem 0 0.3rem;
            }
        }
        .mobile {
            padding: 0.45rem 0 0.5rem;
        }
        .ib-page {
            position: static;
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                margin: 0;
                background-color: #ebebeb;
                height: 3px;
                span {
                    display: none;
                }
                &:after {
                    width: 100%;
                    background-color: $color;
                    opacity: 0;
                }
                &.on:after {
                    opacity: 1;
                }
            }
        }
    }
    .i-strength {
        padding: 0.9rem 0.3rem;
        box-sizing: border-box;
        .tit {
            padding: 0;
        }
        .info {
            width: 100%;
            font-size: 0.26rem;
            line-height: 1.8;
        }
        .m-list {
            li {
                margin-top: 0.6rem;
                position: relative;
                .ring2 {
                    width: 5%;
                    position: absolute;
                    top: 30%;
                    left: 9.5%;
                    z-index: 1;
                    transform-origin: left top;
                    animation: myRotate2 10s linear infinite;
                }
                h3 {
                    font-size: 0.4rem;
                    position: absolute;
                    top: 17%;
                    left: 8%;
                }
                p {
                    font-size: 0.36rem;
                    position: absolute;
                    top: 58%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
    .i-product {
        padding-top: 0.7rem;
        .info {
            padding-bottom: 0.4rem;
            text-align: center;
            .fl,
            .fr {
                float: none;
                width: 100%;
            }
            p {
                padding-top: 0.35rem;
                font-size: 0.26rem;
                color: #555;
            }
        }
    }
    #p-swiper {
        padding: 0 0.3rem 0.7rem;
        .page {
            padding-top: 0.6rem;
        }
        .p-list {
            li {
                width: 100%;
            }
        }
    }
    .i-news {
        padding-top: 0.7rem;
        .text-box {
            background-color: #f7f7f7;
            padding: 0.65rem 0.3rem;
        }
        .tit-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .more {
            padding: 0;
            border: none;
            font-size: 0.26rem;
            img {
                display: none;
            }
        }
    }
    .new-black {
        background: #f7f7f7;
        padding: 0 0.3rem 0.6rem;
        .ct {
            padding: 0;
        }
        .info {
            width: 100%;
            padding-bottom: 0.3rem;
        }
        .img-box {
            display: none;
        }
        .page {
            display: none;
        }
        .tit {
            font-size: 0.3rem;
            padding-bottom: 0.3rem;
        }
        .des {
            font-size: 0.26rem;
            color: #555;
        }
        .info-box {
            height: auto;
        }
    }
    .i-service {
        padding-top: 0.7rem;
        padding-bottom: 1.3rem;
        .tit {
            text-align: center;
            padding-bottom: 0.4rem;
        }
        .bg-img {
            display: none;
        }
    }
    .s-box {
        padding: 0.8rem 0.5rem;
        margin: 0 0.3rem;
        width: auto;
        box-sizing: border-box;
        .tab {
            padding-top: 0.7rem;
            h3 {
                font-size: 0.3rem;
            }
        }
        .tab-list {
            .text {
                color: rgba(0, 0, 0, 0.73);
                font-size: 0.26rem
            }
        }
        .tags {
            padding-top: 0.3rem;
            .img-box img {
                width: 0.7rem;
                height: 0.7rem;
            }
            li p {
                font-size: 0.26rem;
            }
        }
    }
    .i-consult {
        padding: 0.6rem 0;
        background-image: url('/assets/image/i_consult_bg2.png');
        background-size: cover;
        .tit {
            font-size: 0.36rem;
            padding-bottom: 0.5rem;
        }
        .consult-form,
        .consult-tel {
            width: 88%;
            float: none;
        }
        .form-item {
            label {
                font-size: 0.26rem;
                width: 1.5rem;
            }
        }
        .consult-tel {
            h5 {
                text-align: left;
                font-size: 0.3rem;
                padding-bottom: 0.3rem;
            }
            .tel-num {}
        }
        .submit-btn {
            width: 2.3rem;
            height: 0.74rem;
            border-radius: 0.37rem;
            font-size: 0.26rem;
        }
        .consult-tel {
            padding-top: 0.3rem;
            padding-bottom: 0.5rem;
        }
        .form-explain {
            font-size: 0.24rem;
        }
    }
}