@charset "utf-8";
    /*主色调*/
    $color: #002f87;
    //移动 pc 分界
    $divide:992px;
    //movile active bgColor
    $active-bg:#efefef;
    /*文字文本*/
    @mixin text($fontsize,$color,$line-height){
        font-size: $fontsize;
        color:$color;
        line-height: $line-height;
    }
    /*单行省略号*/
    @mixin dan($width){
        max-width: $width;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-wrap: normal;
    }
    /*多行省略号*/
    @mixin duo($line){
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: $line;
        -webkit-box-orient: vertical;
    }

    /**清除浮动**/
    @mixin clear(){
        &:after{
            content:"";
            clear:both;
            display:block;
            visibility:hidden;
            width:0;
            height:0;
        }
    }

    @mixin obj-img($v){
        img{
            object-fit: $v;
            width: 100%;
            height: 100%;
        }
    }

    @mixin hide-scroll{
        &::-webkit-scrollbar {
            height: 0;
            width: 0;
        }
    }

 