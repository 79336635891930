@charset "UTF-8";
/*主色调*/
/*文字文本*/
/*单行省略号*/
/*多行省略号*/
/**清除浮动**/
.i-tit {
  font-size: 0.3rem;
  font-weight: normal;
}

.i-indent {
  padding-left: 0.9rem;
  box-sizing: border-box;
}

.i-about-box {
  padding: 1.4rem 0;
  box-sizing: border-box;
}

.i-about-box .des {
  font-size: 0.16rem;
  line-height: 1.875;
}

.i-about-box .fl {
  width: 51%;
}

.i-about-box .fl .des {
  padding: 0.6rem 0 0.5rem;
}

.i-about-box .fr {
  position: static;
  width: 32%;
  box-sizing: border-box;
}

.i-about-box .fr .tit {
  font-size: 0.22rem;
  padding: 0.35rem 0 0.4rem;
}

.ib-page {
  position: absolute;
  top: 1.4rem;
  right: 0;
  z-index: 1;
}

.ib-page .line {
  display: inline-block;
  width: 0.6rem;
  height: 1px;
  background-color: #08101f;
  position: relative;
  transition: all 0.1s;
  vertical-align: middle;
  transition: all 0.3s;
}

@media (max-width: 1200px) {
  .ib-page .line {
    width: 0.4rem;
  }
}

.ib-page .line:after {
  content: "";
  display: block;
  width: 66.666666%;
  height: 100%;
  background-color: #fff;
  z-index: 1;
  transition: all 0.3s;
  opacity: 1;
}

.ib-page .text {
  position: absolute;
  top: 0;
  left: -8px;
  transform: translate(-150%, 0);
  color: #666;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
}

.ib-page li {
  position: relative;
  cursor: pointer;
}

.ib-page li ~ li {
  margin-top: 0.45rem;
}

.ib-page li.on .line:after {
  width: 0rem;
}

.ib-page li.on .text {
  opacity: 1;
  transform: translate(-100%, 0);
}

.i-strength {
  width: 100%;
  padding-bottom: 0.9rem;
  background: url("/assets/image/i-strength.jpg") no-repeat center;
  background-size: cover;
  color: #fff;
}

.i-strength .tit {
  padding-top: 0.9rem;
}

.i-strength .a-img {
  text-align: center;
  margin-top: 3.5%;
}

.i-strength .info {
  width: 33%;
  font-size: 0.16rem;
  line-height: 1.5;
  margin: 5% auto 0;
}

.i-strength .box {
  width: 37%;
  position: relative;
  display: inline-block;
}

.i-strength .box .ring {
  position: absolute;
  top: -4%;
  left: 50%;
  height: auto;
  width: 52.8%;
  transform: translateX(-50%);
  z-index: 1;
  transform-origin: center;
  animation: myRotate 50s linear infinite;
}

.i-strength .list li {
  position: absolute;
  z-index: 1;
  width: 40%;
}

.i-strength .list li p {
  position: absolute;
  top: 44%;
  left: 50%;
  font-size: 0.16rem;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.i-strength .list .c-box {
  width: 33%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-48%, -15%);
  z-index: 1;
}

.i-strength .list .c-box h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-35%, -60%);
  z-index: 1;
  font-size: 0.3rem;
}

.i-strength .list .c-box .ring2 {
  width: 26%;
  position: absolute;
  top: 46%;
  left: 57%;
  transform-origin: top left;
  animation: myRotate2 10s linear infinite;
}

.i-strength .list .lt {
  top: 0;
  left: 0;
  transform: translate(-40%, 0%);
}

.i-strength .list .lb {
  bottom: 0;
  left: 0;
  transform: translate(-40%, 0%);
}

.i-strength .list .rt {
  top: 0;
  right: 0;
  transform: translate(40%, 0%);
}

.i-strength .list .rb {
  bottom: 0;
  right: 0;
  transform: translate(40%, 0%);
}

.i-strength .list .rt .c-box,
.i-strength .list .rb .c-box {
  left: unset;
  right: 0;
  transform: translate(48%, -15%);
}

.i-strength .list .rt .c-box h3,
.i-strength .list .rb .c-box h3 {
  transform: translate(-88%, -60%);
}

.i-strength .list .rt .c-box .ring2,
.i-strength .list .rb .c-box .ring2 {
  left: 46%;
}

@keyframes myRotate {
  from {
    transform: translateX(-50%) rotate(0);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

@keyframes myRotate2 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.i-product {
  padding-top: 1.2rem;
  overflow: hidden;
}

.i-product .info {
  padding-bottom: 0.9rem;
}

.i-product .info:after {
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.i-product .info p {
  width: 50%;
  line-height: 1.6;
  font-size: 0.16rem;
}

.i-product .p-list li {
  width: 61.25%;
}

#p-swiper {
  position: relative;
  padding-bottom: 1rem;
}

#p-swiper .page {
  padding-top: 1rem;
  font-size: 0;
  text-align: center;
}

#p-swiper .page .item {
  display: inline-block;
  width: 0.48rem;
  height: 3px;
  background-color: #ebebeb;
  transition: all 0.3s;
}

#p-swiper .page .item.on {
  background-color: #002f87;
}

.i-news {
  padding-top: 2.2rem;
  overflow: hidden;
}

.i-news .tit-box {
  display: inline-block;
}

.i-news .text-box {
  padding-bottom: 1.2rem;
}

.i-news .more {
  display: block;
  font-size: 0.14rem;
  line-height: 1.8;
  padding-top: 0.15rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #929292;
}

.i-news .more img {
  vertical-align: middle;
}

@media (max-width: 1440px) {
  .i-news {
    padding-top: 1rem;
  }
  .i-news .text-box {
    padding-bottom: 0.7rem;
  }
}

.new-black {
  background: linear-gradient(to right, #f7f7f7 75.7%, #fff 24.3%);
  padding: 2rem 0;
}

.new-black .tit {
  font-size: 0.24rem;
  line-height: 1.4;
  padding-bottom: 0.4rem;
}

.new-black .des {
  font-size: 0.16rem;
  line-height: 1.8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  max-height: 2.5rem;
}

.new-black .info {
  width: 47%;
}

.new-black .info-box {
  height: 4.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.new-black .img-box {
  position: absolute;
  bottom: 0;
  right: -1.6rem;
  width: 43%;
  z-index: 1;
}

.new-black .img-box img {
  width: 100%;
}

.new-black .view a {
  display: block;
  text-align: center;
  font-size: 0.16rem;
  color: #fff;
  height: 0.44rem;
  line-height: 0.44rem;
  width: 1.72rem;
  border-radius: 0.22rem;
  background-color: #002f87;
  position: relative;
  text-indent: 3em;
}

.new-black .view .icon {
  position: absolute;
  top: 50%;
  left: 0.22rem;
  z-index: 1;
  width: 0.24rem;
  transform: translateY(-50%);
}

.new-black .page {
  width: 3px !important;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0 !important;
  z-index: 1;
}

.new-black .page span {
  transition: all 0.3s;
  background-color: #ebebeb;
  width: 100%;
  height: 50%;
  display: block;
}

.new-black .page .on {
  background-color: #002f87;
}

@media (max-width: 1440px) {
  .new-black {
    padding: 1rem 0;
  }
}

.i-service {
  padding-top: 1.2rem;
  padding-bottom: 2.8rem;
}

.i-service .tit {
  padding-bottom: 3rem;
}

.i-service .bg-img {
  position: absolute;
  right: 0;
  top: -2rem;
  z-index: -1;
  width: 72%;
}

@media (max-width: 1440px) {
  .i-service {
    padding-bottom: 2rem;
  }
}

.s-box {
  background-color: #f7f7f7;
  padding: 1rem 1.28rem 1.1rem;
  width: 49%;
  box-sizing: border-box;
  margin-left: -10%;
}

.s-box .tab {
  font-size: 0;
  padding-top: 0.45rem;
  position: relative;
}

.s-box .tab h3 {
  font-size: 0.3rem;
  display: inline-block;
  width: 50%;
  border-bottom: 3px solid #cecece;
  padding-bottom: 0.15rem;
  cursor: pointer;
}

.s-box .tab h3:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: url("/assets/image/service_text.png") no-repeat left top;
  background-size: auto 0.56rem;
  transition: all 0.2s;
  z-index: 0;
  pointer-events: none;
}

.s-box .tab h3:nth-child(2):after {
  background-image: url("/assets/image/service_text2.png");
  background-position: right top;
}

.s-box .tab h3.on {
  border-color: #333333;
}

.s-box .tab h3.on:after {
  opacity: 1;
}

.s-box .tab h3:nth-child(2) {
  text-align: right;
}

.s-box .tab-list {
  padding-top: 0.5rem;
}

.s-box .tab-list .text {
  color: rgba(0, 0, 0, 0.73);
  line-height: 1.875;
  font-size: 0.16rem;
  height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.s-box .tags {
  margin: 0 -0.38rem;
}

.s-box .tags:after {
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.s-box .tags li {
  float: left;
  margin: 0.3rem 0.4rem 0;
  text-align: center;
}

.s-box .tags li .img-box {
  width: 0.52rem;
  height: 0.52rem;
  margin: 0 auto;
  margin-bottom: 0.18rem;
}

.s-box .tags li .img-box img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.s-box .tags li p {
  font-size: 0.13rem;
}

@media (max-width: 1440px) {
  .s-box {
    width: 55%;
    min-width: 6rem;
    margin-left: -10%;
  }
}

.i-consult {
  background: #fcfcfc url("/assets/image/i_consult_bg.png") no-repeat right top;
  background-size: auto 100%;
  padding: 0.6rem;
}

.i-consult .tit {
  font-size: 0.4rem;
  font-weight: normal;
  padding-bottom: 0.5rem;
  color: #3c3c3c;
}

.i-consult .consult-box:after {
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.i-consult .consult-tel {
  float: right;
  width: 30%;
}

.i-consult .consult-tel h5 {
  font-size: 0.18rem;
  text-align: center;
  color: #3c3c3c;
  padding: 0.1rem 0 0.3rem;
}

.i-consult .consult-tel .tel-num {
  font-size: 0.4rem;
  color: #002f87;
  vertical-align: middle;
  padding-left: 0.2rem;
}

.i-consult .consult-tel .tel-img {
  height: 0.34rem;
  width: auto;
  vertical-align: middle;
}

.i-consult .consult-tel .tel-box {
  white-space: nowrap;
}

.i-consult .consult-form {
  float: left;
  width: 62.5%;
}

.i-consult .consult-form:after {
  content: "";
  clear: both;
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}

.i-consult .form-item {
  float: left;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: top;
  height: 1rem;
}

.i-consult .form-item label {
  font-size: 0.18rem;
  color: #3c3c3c;
  flex-shrink: 0;
  line-height: 0.66rem;
  width: 1rem;
}

.i-consult .form-item .from-control {
  width: 100%;
}

.i-consult .form-item input,
.i-consult .form-item .my-ele {
  width: 100%;
  height: 0.66rem;
  line-height: 0.66rem;
  box-sizing: border-box;
  padding: 0 0.2rem;
  border: 1px solid #e7e7e7;
  border-radius: 1px;
  transition: all 0.3s;
  outline: none;
  color: #666;
}

.i-consult .form-item .mini input,
.i-consult .form-item .mini .my-ele {
  width: 1.6rem;
}

.i-consult .form-explain {
  font-size: 0.14rem;
  padding-top: 0.06rem;
  padding-bottom: 0.1rem;
  color: red;
}

.i-consult .submit-btn {
  background-color: #002f87;
  color: #fff;
  font-size: 0.16rem;
  line-height: 0.5rem;
  width: 1.7rem;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .i-consult .form-item {
    width: 100%;
  }
  .i-consult .form-item input {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .i-tit {
    font-size: 0.36rem;
  }
  .i-indent {
    padding-left: 0;
  }
  .i-about-box {
    padding: 0.7rem 0 0.6rem;
  }
  .i-about-box .fl,
  .i-about-box .fr {
    float: none;
    width: 100%;
  }
  .i-about-box .des {
    font-size: 0.26rem;
    color: #555;
  }
  .i-about-box .fl .des {
    padding: 0.3rem 0 0.4rem;
  }
  .i-about-box .fr .tit {
    font-size: 0.36rem;
    padding: 0.6rem 0 0.3rem;
  }
  .i-about-box .mobile {
    padding: 0.45rem 0 0.5rem;
  }
  .i-about-box .ib-page {
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .i-about-box .ib-page li {
    margin: 0;
    background-color: #ebebeb;
    height: 3px;
  }
  .i-about-box .ib-page li span {
    display: none;
  }
  .i-about-box .ib-page li:after {
    width: 100%;
    background-color: #002f87;
    opacity: 0;
  }
  .i-about-box .ib-page li.on:after {
    opacity: 1;
  }
  .i-strength {
    padding: 0.9rem 0.3rem;
    box-sizing: border-box;
  }
  .i-strength .tit {
    padding: 0;
  }
  .i-strength .info {
    width: 100%;
    font-size: 0.26rem;
    line-height: 1.8;
  }
  .i-strength .m-list li {
    margin-top: 0.6rem;
    position: relative;
  }
  .i-strength .m-list li .ring2 {
    width: 5%;
    position: absolute;
    top: 30%;
    left: 9.5%;
    z-index: 1;
    transform-origin: left top;
    animation: myRotate2 10s linear infinite;
  }
  .i-strength .m-list li h3 {
    font-size: 0.4rem;
    position: absolute;
    top: 17%;
    left: 8%;
  }
  .i-strength .m-list li p {
    font-size: 0.36rem;
    position: absolute;
    top: 58%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .i-product {
    padding-top: 0.7rem;
  }
  .i-product .info {
    padding-bottom: 0.4rem;
    text-align: center;
  }
  .i-product .info .fl,
  .i-product .info .fr {
    float: none;
    width: 100%;
  }
  .i-product .info p {
    padding-top: 0.35rem;
    font-size: 0.26rem;
    color: #555;
  }
  #p-swiper {
    padding: 0 0.3rem 0.7rem;
  }
  #p-swiper .page {
    padding-top: 0.6rem;
  }
  #p-swiper .p-list li {
    width: 100%;
  }
  .i-news {
    padding-top: 0.7rem;
  }
  .i-news .text-box {
    background-color: #f7f7f7;
    padding: 0.65rem 0.3rem;
  }
  .i-news .tit-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .i-news .more {
    padding: 0;
    border: none;
    font-size: 0.26rem;
  }
  .i-news .more img {
    display: none;
  }
  .new-black {
    background: #f7f7f7;
    padding: 0 0.3rem 0.6rem;
  }
  .new-black .ct {
    padding: 0;
  }
  .new-black .info {
    width: 100%;
    padding-bottom: 0.3rem;
  }
  .new-black .img-box {
    display: none;
  }
  .new-black .page {
    display: none;
  }
  .new-black .tit {
    font-size: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .new-black .des {
    font-size: 0.26rem;
    color: #555;
  }
  .new-black .info-box {
    height: auto;
  }
  .i-service {
    padding-top: 0.7rem;
    padding-bottom: 1.3rem;
  }
  .i-service .tit {
    text-align: center;
    padding-bottom: 0.4rem;
  }
  .i-service .bg-img {
    display: none;
  }
  .s-box {
    padding: 0.8rem 0.5rem;
    margin: 0 0.3rem;
    width: auto;
    box-sizing: border-box;
  }
  .s-box .tab {
    padding-top: 0.7rem;
  }
  .s-box .tab h3 {
    font-size: 0.3rem;
  }
  .s-box .tab-list .text {
    color: rgba(0, 0, 0, 0.73);
    font-size: 0.26rem;
  }
  .s-box .tags {
    padding-top: 0.3rem;
  }
  .s-box .tags .img-box img {
    width: 0.7rem;
    height: 0.7rem;
  }
  .s-box .tags li p {
    font-size: 0.26rem;
  }
  .i-consult {
    padding: 0.6rem 0;
    background-image: url("/assets/image/i_consult_bg2.png");
    background-size: cover;
  }
  .i-consult .tit {
    font-size: 0.36rem;
    padding-bottom: 0.5rem;
  }
  .i-consult .consult-form,
  .i-consult .consult-tel {
    width: 88%;
    float: none;
  }
  .i-consult .form-item label {
    font-size: 0.26rem;
    width: 1.5rem;
  }
  .i-consult .consult-tel h5 {
    text-align: left;
    font-size: 0.3rem;
    padding-bottom: 0.3rem;
  }
  .i-consult .submit-btn {
    width: 2.3rem;
    height: 0.74rem;
    border-radius: 0.37rem;
    font-size: 0.26rem;
  }
  .i-consult .consult-tel {
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
  }
  .i-consult .form-explain {
    font-size: 0.24rem;
  }
}
